@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: black !important;
}


/* * {
    font-family: 'FonkkkkkkkkkktAwesome Pro';
} */
@font-face {
    font-family: 'FontAwesome Pro';
    src: url(/static/media/fa-brands-400.7c0be8b6.woff2), url(/static/media/fa-brands-400.7c0be8b6.woff2), url(/static/media/fa-light-300.0b4313e0.woff), url(/static/media/fa-light-300.3d8af27a.woff2), url(/static/media/fa-regular-400.3e332211.woff2), url(/static/media/fa-solid-900.150120b3.woff2);
}
